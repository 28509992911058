import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box } from "../../components/Core";

import svgCurve from "../../assets/image/svg/l1-curve-cta.svg";

const RightCard = styled(Box)`
  position: absolute;
  top: 0;
  right: -275px;
`;

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Section bg="secondary" className="position-relative">
      <RightCard>
        <img src={svgCurve} alt="" className="img-fluid" />
      </RightCard>
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg="6">
            <Box mb={5} className="text-center">
              <Title color="light">Ready to talk?</Title>
            </Box>
            <Box>
              <Button to="/contact">Contact</Button>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CTA;
