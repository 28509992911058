import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/about/Hero";
import CTA from "../sections/about/CTA";
import { Section, Text } from "../components/Core";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer";
import { Container } from "react-bootstrap";

const About = ({ data }) => {
  return (
    <PageWrapper>
      <Section>
        <Hero title={data.about.frontmatter.title} bodyText={data.about.body} />
        <Section className="mx-3">
          <Container>
            <Text>
              <MDXRenderer>{data.experience.body}</MDXRenderer>
            </Text>
          </Container>
        </Section>
        <CTA />
      </Section>
    </PageWrapper>
  );
};
export default About;

export const query = graphql`
  query {
    experience: mdx(frontmatter: { id: { eq: "experience" } }) {
      body
    }
    about: mdx(frontmatter: { id: { eq: "about" } }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
