import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Text, Box } from "../../components/Core";
import { StaticImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

const SectionStyled = styled(Section)`
  background-image: linear-gradient(
    147deg,
    rgba(141, 141, 236, 0.17) 0%,
    rgba(84, 84, 212, 0) 100%
  );
`;

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  /* position: absolute;
  top: 40px;
  left: 40px;
  height: 650px; */
`;

const Hero = ({ title, bodyText }) => {
  return (
    <>
      <SectionStyled className="mt-4">
        <Container>
          <Row>
            <Col lg="6">
              <div>
                <Title variant="hero">{title}</Title>
                <Text>
                  <MDXRenderer>{bodyText}</MDXRenderer>
                </Text>
              </div>
            </Col>
            <Col
              lg="6"
              className="pl-lg-5"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <ContentImg>
                <StaticImage
                  src="../../assets/image/jpeg/christine-profile4.jpg"
                  alt="christine colman"
                  placeholder="blurred"
                />
              </ContentImg>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};
export default Hero;
